import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, CardFooter, Divider, Image, Checkbox, Input, Button } from "@nextui-org/react";
import { CookiesProvider, useCookies } from 'react-cookie'
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import Navbar from './Navbar'
import { MailIcon } from './Icons/MailIcon.jsx';
import { LockIcon } from './Icons/LockIcon.jsx';

export default function Login() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['user'])
    const [Email, setEmail] = React.useState("");
    const [isLoadingBtn, setisLoading] = React.useState(false);
    const [Password, setPassword] = React.useState("");
    function LoginAPI(){
        setisLoading(true);
        if(Email !== "" && Password !== ""){
            localStorage.setItem('user', JSON.stringify({Email, Password}));
            toast.success('Başarıyla giriş yapıldı!');
            setisLoading(false);
            navigate("/profile");
            return;
        }else{
            setisLoading(false);
            toast.error('Lütfen tüm alanları doldurun!');
        }
    }

    useEffect(() => {
        const user = localStorage.getItem('user');
        if(user){
            navigate("/");
            return;
        }
    })

    return (
        <>
        <Navbar />
        <div><Toaster/></div>
        <div className="">
            <Card className="max-w-[700px] mx-auto mt-8">
                <CardHeader className="flex gap-3">
                    <div className="flex flex-col">
                        <p className="text-md">Giriş Yap</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody>
                    <Input
                        autoFocus
                        endContent={
                            <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                        }
                        label="Email"
                        variant="bordered"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        endContent={
                            <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                        }
                        label="Şifre"
                        type="password"
                        variant="bordered"
                        className="mt-4"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="flex py-2 px-1 justify-between">
                        <Checkbox
                            classNames={{
                                label: "text-small",
                            }}
                        >
                            Beni Hatırla
                        </Checkbox>
                        <Link color="primary" to="/forgot-password" size="sm">
                            Şifremi Unuttum?
                        </Link>
                    </div>
                    <div className="flex py-2 px-1 w-100">
                        <Button radius="full" onClick={LoginAPI} color="primary" className="text-white shadow-lg w-full" isLoading={isLoadingBtn}>
                            Giriş Yap
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
        </>
    )
}