import React from 'react'
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Button, User, Image, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbars() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const menuItems = [
    "Profile",
    "Dashboard",
    "Activity",
    "Analytics",
    "System",
    "Deployments",
    "My Settings",
    "Team Settings",
    "Help & Feedback",
    "Log Out",
  ];

  return (
    <>
    <Navbar onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
        <Link color="foreground" to="/">
        <Image
            width={150}
            alt="NextUI hero Image"
            src="https://kralakademiuzem.com/images/logo-site-turuncu.png"
          />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link color="foreground" to="/books">
            Kitaplarımız
          </Link>
        </NavbarItem>
        <NavbarItem isActive>
          <Link color="foreground" to="/courses">
            Kurslarımız
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" to="/about">
            Hakkımızda
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        {localStorage.getItem('user') ? (
        <NavbarItem>
          <Dropdown >
            <DropdownTrigger>
              <User   
                name="Arda Tekinalp"
                description="Back-end Dev."
                avatarProps={{
                  src: "https://i.hizliresim.com/rjb05es.jpeg"
                }}
              />  
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem key="profile">Profilim</DropdownItem>
              <DropdownItem key="settings">Ayarlar</DropdownItem>
              <DropdownItem key="logout" className="text-danger" color="danger" onPress={() => navigate("/logout")}>
                Çıkış Yap
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
        ) : (
          <>
            <NavbarItem className="hidden lg:flex">
          <Link to="/login">Giriş Yap</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="primary" to="/register" variant="flat">
            Kayıt Ol
          </Button>
        </NavbarItem>
          </>
        )}
      </NavbarContent>
      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              color={
                index === 2 ? "primary" : index === menuItems.length - 1 ? "danger" : "foreground"
              }
              className="w-full"
              href="#"
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
    </>
  );
}
