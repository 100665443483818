import React, {useEffect} from 'react'
import Navbar from './Navbar'
import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.removeItem('user');
        navigate("/");
        return;
    })
}

export default Logout