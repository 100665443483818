import React from 'react'
import Navbar from './Navbar'
import { Flex } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'

 const Home = () => {
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      name: 'GECE KUŞU SORU ÇÖZÜM KAMPI - ÜÇLÜ (SOSYAL B. ÖABT + GY-GK + EB) VİDEO DERS',
      href: '#',
      price: '659,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img57523.jpeg',
      imageAlt: '',
    },
    {
      id: 2,
      name: '2024 PDR ÖABT + EB + GYGK KRAL ÜÇLÜ CANLI DERS PAKETİ',
      href: '#',
      price: '2.074,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img60190.jpeg',
      imageAlt: '',
    },
    {
      id: 3,
      name: '2024 SINIF + GYGK + EB KRAL ÜÇLÜ CANLI DERS PAKETİ',
      href: '#',
      price: '3.549,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img35086.jpeg',
      imageAlt: '',
    },
    {
      id: 4,
      name: '2024 OKUL ÖNCESİ ÖABT CANLI DERS PAKETİ',
      href: '#',
      price: '2.249,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img38796.jpeg',
      imageAlt: '',
    },
  ]

  const books = [
    {
      id: 1,
      name: 'Hiyerarşi SOSYAL B. ÖABT + GY-GK + EB 3LÜ DENEME SETİ (TOPLAM 23 DENEME)',
      href: '#',
      price: '259,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img68230.jpeg',
      imageAlt: '',
    },
    {
      id: 2,
      name: 'Kral Akademi Hiyerarşi Sosyal Bilgiler ÖABT QR Çözümlü 7+3+3 VIP Deneme (TOPLAM 13 DENEME BİR ARADA!)',
      href: '#',
      price: '98,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img47597.jpeg',
      imageAlt: '',
    },
    {
      id: 3,
      name: 'Kral Akademi Hiyerarşi Genel Yetenek - Genel Kültür + Eğitim Bilimleri İkili QR Çözümlü 5+3 VIP Deneme (TOPLAM 16 DENEME BİR ARADA!) %50 İNDİRİMLE!',
      href: '#',
      price: '161,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img18253.jpeg',
      imageAlt: '',
    },
    {
      id: 4,
      name: 'Kral Akademi Hiyerarşi Eğitim Bilimleri QR Çözümlü 5+3 VIP Deneme (TOPLAM 8 DENEME BİR ARADA!) %50 İNDİRİMLE!',
      href: '#',
      price: '78,00₺',
      imageSrc: 'https://kralakademiuzem.com/images/img31845.jpeg',
      imageAlt: '',
    },
  ]
  
  const features = [
    {
      name: 'Canlı Dersler',
      description:
        'İnteraktif canlı derslerimiz ile sizlerle canlı olarak ders işliyoruz!',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Video Dersler',
      description:
        'Canlı yayın bitiminin ardından sisteme yüklenen canlı yayın videolarını sonradan izleme imkanı!',
      icon: LockClosedIcon,
    },
    {
      name: 'Online Denemeler',
      description:
        'Sizler için hazırlayacağımız online denemelerimize katılım sağlayarak kendinizi ölçebilirsiniz!',
      icon: ArrowPathIcon,
    },
    {
      name: 'Kesintisiz Hizmet',
      description:
        'Bizlere dilediğiniz vakitte ulaşarak soru ve sorunlarınızı iletebilirsiniz!',
      icon: FingerPrintIcon,
    },
  ]

  const links = [
    { name: 'Kurslarımız', href: '/courses' },
    { name: 'Kitaplarımız', href: '/books' },
  ]
  const stats = [
    { name: 'Toplam Kurs', value: '198' },
    { name: 'Toplam Öğrenci', value: '71902' },
    { name: 'Toplam Kategori', value: '20' },
    { name: 'Toplam Sınav', value: '26' },
  ]
   return (
     <div className="bg-dark">
         <Navbar />
         <div className="relative isolate px-6 pt-2 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white-600 ring-2 ring-white-900/10 hover:ring-white-900/20">
                En Çok Atandıran Online Eğitim Platformu!
              </div>
            </div>
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white-800 sm:text-6xl">
                Kral Akademi Uzem
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Kral Akademi Uzem, tecrübeli yöneticileri, öğretmenleri ve çalışanlarıyla alanında başarı odaklı bir kurumdur. 
              Uzaktan eğitim sistemi, en gelişmiş ve en yüksek donanımlarla desteklenmektedir.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/register"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Kayıt Ol
                </Link>
                <Link to="/courses" className="text-sm font-semibold leading-6 text-white-900">
                  Kurslarımız <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <div className="mx-auto max-w-6xl py-20 sm:py-28 lg:py-36">
        <h1 className="text-3xl font-bold tracking-tight text-white-800 sm:text-5xl text-center mx-auto mb-4">
          Kurslarımız
        </h1>
        <div className="">
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
          >
            {products.map((product) => (
              <SwiperSlide>
                <Link key={product.id} to={product.href} className="group">
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <h3 className="mt-4 text-sm text-white-700">{product.name}</h3>
                  <p className="mt-1 text-lg font-medium text-white-900">{product.price}</p>
                </Link>
              </SwiperSlide>
          ))}
          </Swiper>
          <div className="mt-20">
            <h1 className="text-3xl font-bold tracking-tight text-white-800 sm:text-5xl text-center mx-auto mb-4">
              Kitaplarımız
            </h1>
            <Swiper
              spaceBetween={50}
              slidesPerView={4}
              className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
            >
              {books.map((product) => (
                <SwiperSlide>
                  <Link key={product.id} to={product.href} className="group">
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                      <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className="h-full w-full object-cover object-center group-hover:opacity-75"
                      />
                    </div>
                    <h3 className="mt-4 text-sm text-white-700">{product.name}</h3>
                    <p className="mt-1 text-lg font-medium text-white-900">{product.price}</p>
                  </Link>
                </SwiperSlide>
            ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white-900 sm:text-4xl">
            Neler Yapıyoruz?
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Kral Akademi Online Eğitim Platformunun kısaca ne olduğuna aşağıdan bakabilirsiniz, unutmayın ki bunlar sadece bir kaçı ;)
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-white-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 mt-20">
      <img
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center blur"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Sende bize katıl!</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Sende kurslarımızı ve kitaplarımızı incele ve sipariş ver, hemen aramıza katıl ve çalışmalara başla!
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Bültene abone ol.</h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Kampanyalar ve duyurular için Email bültenimize abone olabilirsiniz.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Email adresiniz"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Abone ol
              </button>
            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Haftalık Bülten</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Kampanya ve duyurularımızı haftalık olarak sizlerle paylaşacağız.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Spam Yok</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Sizlere asla spam Email'ler göndermiyoruz.
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
    <footer className="rounded-lg shadow bg-dark">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">2024 © <a href="https://kralakademiuzem.com/" className="hover:underline">Kral Akademi Uzem</a> | Tüm Hakları Saklıdır.</span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <Link to="/about" className="me-4 md:me-6">Hakkımızda</Link>
          </li>
          <li>
            <Link to="/distance-sales-agreement" className="me-4 md:me-6">Mesafeli Satış Sözleşmesi</Link>
          </li>
          <li>
            <Link to="/kvkk" className="me-4 md:me-6">K.V.K.K</Link>
          </li>
          <li>
            <Link to="/contact" className="">İletişim</Link>
          </li>
        </ul>
      </div>
    </footer>
    </div>
   )
 }

 export default Home