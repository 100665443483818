import React from 'react'
import Navbar from './Navbar'
import {Card, CardHeader, CardBody, CardFooter, Divider, Link, Image} from "@nextui-org/react";

const About = () => {
  return (
    <div className="bg-black">
        <Navbar />
        <Card className="max-w-[1000px] mx-auto mt-8 mb-8">
        <CardHeader className="flex gap-3">
          <div className="flex flex-col">
            <p className="text-md">Hakkımızda</p>
          </div>
        </CardHeader>
        <Divider/>
        <CardBody>
          <p>Hakkımızda sayfası içeriği.</p>
        </CardBody>
      </Card>
    </div>
  )
}

export default About